import React from "react";
import { HeaderContainer } from "./Header.styles";
import { Logo } from "./Logo";

export const Header = ({ logoTitle, logoSubtitle, userDisplayName }) => {
  /** TGDS Portals specific - The following section is commented out as :
   * userToken is used instead of loggedInUser so there's no need to show the user name in the header
   * Impersonation is not needed
   */
  // const { loggedInUser } = useUsers();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const [openImpersonationSection, setOpenImpersonationSection] =
  //   React.useState(false);
  // const handleViewAsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleViewAsMenuClose = () => {
  //   setAnchorEl(null);
  //   setOpenImpersonationSection(true);
  // };
  // const handleViewAsMenuClickAway = () => {
  //   setAnchorEl(null);
  //   setOpenImpersonationSection(false);
  // };

  return (
    <HeaderContainer>
      {/* 
       **** TGDS Portals specific - Impersonation is not needed ****
      {openImpersonationSection && (
        <ImpersonationSection
          setOpenImpersonationSection={setOpenImpersonationSection}
        />
      )} */}
      <header id="header" className="header-local" role="banner">
        <div className="container">
          <span className="skip-to-links">
            <a href="#mainContent" className="sr-only sr-only-focusable">
              Skip to Content
            </a>
            <a href="#navigationLocal" className="sr-only sr-only-focusable">
              Skip to Local Navigation
            </a>
          </span>
          <Logo logoTitle={logoTitle} logoSubtitle={logoSubtitle} />
          <div id="headerContent" />
        </div>
        {/* **** TGDS Portals specific - Impersonation is not needed ****
        <div className="display-name">
          {loggedInUser.canImpersonate && !openImpersonationSection ? (
            <>
              <MuiButton
                className="display-name-button"
                endIcon={<MuiArrowDropDownOutlinedIcon />}
                onClick={handleViewAsMenuOpen}
                aria-haspopup="true"
              >
                {userDisplayName}
              </MuiButton>
              <MuiMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleViewAsMenuClickAway}
                keepMounted
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MuiMenuItem
                  onClick={handleViewAsMenuClose}
                  className="impersonation-item"
                >
                  View As ...
                </MuiMenuItem>
              </MuiMenu>
            </>
          ) : (
            <>{userDisplayName}</>
          )}
        </div> */}
      </header>
    </HeaderContainer>
  );
};

/***** TGDS Portals specific - The following code is commented out as Impersonation is not needed ****/
// const ImpersonationSection = (props) => {
//   const navigate = useNavigate();
//   const { setOpenImpersonationSection } = props;

//   const { setAlert, clearAlert } = useAlerts();
//   const {
//     currentUser,
//     setCurrentUser,
//     setAccessDenied,
//     impersonateUserId,
//     initImpersonateUser,
//     clearImpersonateUserId,
//   } = useUsers();

//   const [inputSunetId, setInputSunetId] = React.useState("");

//   const handleSunetIdChange = (sunetId) => {
//     setInputSunetId(sunetId);
//   };

//   const startImpersonate = () => {
//     initImpersonateUser(inputSunetId);
//     setAccessDenied(true);
//     getUser(setCurrentUser, true, false, setAlert, clearAlert, setAccessDenied);
//     navigate("/");
//   };

//   const stopImpersonation = () => {
//     setInputSunetId("");
//     clearImpersonateUserId();
//     getUser(
//       setCurrentUser,
//       false,
//       false,
//       setAlert,
//       clearAlert,
//       setAccessDenied
//     );
//     setOpenImpersonationSection(false);
//     navigate("/");
//   };

//   const cancelImpersonation = () => {
//     setInputSunetId("");
//     setOpenImpersonationSection(false);
//     if (currentUser.permissions === null) {
//       clearImpersonateUserId();
//       getUser(
//         setCurrentUser,
//         false,
//         false,
//         setAlert,
//         clearAlert,
//         setAccessDenied
//       );
//       navigate("/");
//     }
//   };

//   const impersonateWrapper = `impersonateContainer ${
//     currentUser.uid === impersonateUserId ? "impersonating" : ""
//   }`;

//   return (
//     <ImpersonateContainer>
//       <div className={impersonateWrapper}>
//         {currentUser.uid === impersonateUserId ? (
//           <div className="impersonateShow">
//             <div className="gridContainerStyle">
//               <label className="viewAsLabelStyle">
//                 Viewing as {currentUser.displayName}
//               </label>
//               <MuiIconButton
//                 onClick={() => {
//                   stopImpersonation();
//                 }}
//                 id="impersonationBannerCancelImpersonation"
//               >
//                 <MuiCancelIcon />
//               </MuiIconButton>
//             </div>
//           </div>
//         ) : (
//           <div className="impersonateEdit">
//             <div className="gridContainerStyle">
//               <label className="viewAsLabelStyle">View As:</label>
//               <MuiTextField
//                 name="sunetId"
//                 label="SUNet ID"
//                 className="impersonateInputName"
//                 size="small"
//                 variant="outlined"
//                 margin="dense"
//                 fullWidth
//                 value={inputSunetId}
//                 onChange={(e) => handleSunetIdChange(e.target.value)}
//                 onKeyPress={(e) => {
//                   if (e.key === "Enter" && inputSunetId) {
//                     startImpersonate();
//                   }
//                 }}
//               />
//               <MuiIconButton
//                 disabled={!inputSunetId}
//                 onClick={startImpersonate}
//               >
//                 <MuiCheckCircleIcon />
//               </MuiIconButton>
//               <MuiIconButton onClick={cancelImpersonation}>
//                 <MuiCancelIcon />
//               </MuiIconButton>
//             </div>
//           </div>
//         )}
//       </div>
//     </ImpersonateContainer>
//   );
// };
