import {
  Button as MuiButton,
  Dialog as MuiDialog,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const ConfirmationDialog = ({
  title = "",
  open,
  setOpen,
  onConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <MuiDialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirmation-dialog"
    >
      {title && <MuiDialogTitle id="confirm-dialog">{title}</MuiDialogTitle>}
      <MuiDialogContent>
        <MuiTypography variant="body1">
          {t("confirmBackDialog.body")}
        </MuiTypography>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton variant="contained" onClick={() => setOpen(false)}>
          {t("common.no")}
        </MuiButton>
        <MuiButton
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="primary"
        >
          {t("common.yes")}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};
