import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid2 as MuiGrid,
  Step as MuiStep,
  Stepper as MuiStepper,
  StepLabel as MuiStepLabel,
  Typography as MuiTypography,
} from "@mui/material";
import { Theme } from "common";
import { styles } from "app/shared/ui/styles";
import { useTrainee } from "app/services/traineeService";
import { SubmittedStep } from "./SubmittedStep";
import { SteppedForm } from "./SteppedForm";

export const TraineePortal = () => {
  const { t } = useTranslation();
  const { traineeData } = useTrainee();

  // Stepper steps translation
  const steps = [
    t("steps.aboutYou.stepperLabel"),
    t("steps.trainingAtStanford.stepperLabel"),
    t("steps.trainingSupport.stepperLabel"),
    t("steps.yourEmployment.stepperLabel"),
    t("steps.subsequentGrants.stepperLabel"),
  ];

  const [activeStep, setActiveStep] = useState(0);

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === steps.length - 1;

  return (
    <>
      {traineeData.uuid && (
        <MuiGrid container direction="column">
          {activeStep === steps.length ? (
            <SubmittedStep />
          ) : (
            <MuiGrid container direction="column" spacing={4}>
              <MuiGrid>
                <MuiTypography
                  variant="h1"
                  id="rootTitle"
                  sx={styles.headerTitleRoot}
                >
                  {t("common.portalName")}
                </MuiTypography>
              </MuiGrid>
              <MuiGrid
                sx={{ background: Theme.palette.common.white, padding: "20px" }}
              >
                <MuiStepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <MuiStep key={label}>
                      <MuiStepLabel>{label}</MuiStepLabel>
                    </MuiStep>
                  ))}
                </MuiStepper>
              </MuiGrid>
              <MuiGrid sx={{ padding: "0px 15px 0px 15px" }}>
                <SteppedForm
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  isFirstStep={isFirstStep}
                  isLastStep={isLastStep}
                />
              </MuiGrid>
            </MuiGrid>
          )}
        </MuiGrid>
      )}
    </>
  );
};
