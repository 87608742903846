import { useEffect, useRef } from "react";

/**
 * Prevents a function from being called too often, waits until a timer elapses to call it again.
 * If you need to cancel a scheduled debounce, you can call .cancel() on the debounced function.
 * @param {callback} func the function to call.
 * @param {delay} wait number of milliseconds to wait before calling the function.
 * @returns {function} the "debounced wrapped" function.
 */
export const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  const debouncedFunction = (...args) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current); // Cleanup on unmount
  }, []);

  return debouncedFunction;
};

/**
 * Returns a descending array of years starting from the current year.
 * @param {integer} numYears
 * @returns {array}
 */
export const getYearsDescending = (numYears) =>
  [...Array(numYears).keys()].map((x) =>
    parseInt(new Date().getFullYear() - x + 0, 10)
  );

/**
 * Formats Component & Activity notation according to the following:
 * - 2 letters followed by space, followed by 3 characters (letters or numbers). Example: HL T32.
 * @param {string} val unformatted string
 * @returns {string} formatted string
 */
export const formatComponentActivity = (val) => {
  val = val
    .trim()
    .replace(/[\W_]+/gi, "")
    .toUpperCase();
  val = val.substring(0, 2).replace(/[^a-zA-Z]/gi, "") + val.substring(2, 5);
  const formatted =
    val.length < 2 ? val : `${val.substring(0, 2)} ${val.substring(2, 5)}`;

  return formatted;
};

export const isString = (val) =>
  typeof val === "string" || val instanceof String;
