import { useState } from "react";
import {
  Box as MuiBox,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFacultySearchApi } from "app/services/facultyService";

// Autocomplete component for faculty search
export const FacultyAutocomplete = ({
  id,
  name,
  label,
  value,
  error,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const { loading, getFacultySearchResultsDebounced } = useFacultySearchApi();
  const minChars = 2;
  const queryTooShortOption = {
    displayText: t("common.autosuggest.queryTooShort", {
      minChars: minChars,
    }),
    disabled: true,
  };
  const [facultyOptions, setFacultyOptions] = useState([queryTooShortOption]);

  // Handles input change and fetches faculty search results
  const handleInputChange = (event, newInputValue) => {
    const trimmedInputValue = newInputValue?.trim();
    if (!trimmedInputValue || trimmedInputValue.length < minChars) {
      setFacultyOptions([queryTooShortOption]);
    } else if (trimmedInputValue !== value?.fullName?.trim()) {
      getFacultySearchResultsDebounced(trimmedInputValue, setFacultyOptions);
    } else {
      return;
    }
  };

  return (
    <MuiAutocomplete
      key={id}
      name={name}
      value={value || ""}
      autoComplete
      selectOnFocus
      openOnFocus
      autoHighlight
      options={facultyOptions}
      loading={loading}
      loadingText={t("common.autosuggest.searching")}
      noOptionsText={t("common.autosuggest.noResults")}
      getOptionDisabled={(option) => option.disabled}
      getOptionLabel={(option) => option.fullName || ""}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <MuiBox
            key={key}
            component="li"
            {...optionProps}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start !important",
            }}
          >
            <MuiTypography variant="body1">{option.displayText}</MuiTypography>
            <MuiTypography variant="body2" color="textSecondary">
              {option.rank ? `${option.rank}` : ""}
              {option.departmentName ? `, ${option.departmentName}` : ""}
            </MuiTypography>
          </MuiBox>
        );
      }}
      onChange={(event, newValue) => {
        const updatedValue = {
          fullName: newValue?.fullName || "",
          universityId: newValue?.universityId || "",
        };
        setFieldValue(name, updatedValue);
      }}
      onInputChange={(event, newInputValue) => {
        handleInputChange(event, newInputValue);
      }}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          required
          variant="outlined"
          label={label}
          error={error}
        />
      )}
    />
  );
};
