import { useState } from "react";
import {
  Grid2 as MuiGrid,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { useTrainee } from "app/services/traineeService";
import { styles } from "app/shared/ui/styles";
import { stepName } from "app/shared/constants";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { AboutYouStep } from "./AboutYouStep";
import { TrainingAtStanfordStep } from "./TrainingAtStanfordStep";
import { TrainingSupportStep } from "./TrainingSupportStep";
import { YourEmploymentStep } from "./YourEmploymentStep";
import { SubsequentGrantsStep } from "./SubsequentGrantsStep";
import {
  aboutYouStepValidation,
  subsequentGrantsStepValidation,
  trainingAtStanfordStepValidation,
  trainingSupportStepValidation,
  yourEmploymentStepValidation,
} from "./formikValidations";

// Stepped form component with formik
export const SteppedForm = ({
  activeStep,
  setActiveStep,
  isFirstStep,
  isLastStep,
}) => {
  const { t } = useTranslation();
  const { traineeData, saveTrainee } = useTrainee();
  const [showConfirm, setShowConfirm] = useState(false);

  // Renders step component based on active step
  const getStepComponent = ({ props }) => {
    switch (activeStep) {
      case 0:
        return <AboutYouStep formikProps={props} />;
      case 1:
        return <TrainingAtStanfordStep formikProps={props} />;
      case 2:
        return <TrainingSupportStep formikProps={props} />;
      case 3:
        return <YourEmploymentStep formikProps={props} />;
      case 4:
        return <SubsequentGrantsStep formikProps={props} />;
      default:
        return <AboutYouStep formikProps={props} />;
    }
  };

  // Gets validation schema based on active step
  const getValidationSchema = () => {
    switch (activeStep) {
      case 0:
        return aboutYouStepValidation;
      case 1:
        return trainingAtStanfordStepValidation;
      case 2:
        return trainingSupportStepValidation;
      case 3:
        return yourEmploymentStepValidation;
      case 4:
        return subsequentGrantsStepValidation;
      default:
        return aboutYouStepValidation;
    }
  };

  // Handles next step
  const handleNext = () => setActiveStep((prev) => prev + 1);
  // Handles back step
  const handleBack = () => setActiveStep((prev) => prev - 1);

  return (
    <Formik
      enableReinitialize
      validationSchema={getValidationSchema()}
      initialValues={{ ...traineeData }}
      onSubmit={async (values, { setSubmitting }) => {
        const formData = {
          ...values,
          stepName: Object.keys(stepName)[activeStep],
        };
        await saveTrainee(formData, handleNext);
      }}
    >
      {(formikProps) => {
        return (
          <Form>
            {getStepComponent({ props: formikProps })}
            <MuiGrid container sx={styles.buttonsWrapper} spacing={2}>
              <MuiGrid>
                <MuiButton
                  disabled={isFirstStep || formikProps.isSubmitting}
                  onClick={() => {
                    if (!formikProps.isValid) {
                      setShowConfirm(true);
                    } else {
                      handleBack();
                    }
                  }}
                >
                  {t("common.back")}
                </MuiButton>
              </MuiGrid>
              <MuiGrid>
                <MuiButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!formikProps.isValid || formikProps.isSubmitting}
                >
                  {formikProps.isSubmitting && (
                    <>
                      <MuiCircularProgress
                        size={20}
                        sx={{ marginRight: "10px", color: "#ffffff" }}
                      />
                    </>
                  )}
                  {isLastStep ? t("common.finish") : t("common.next")}
                </MuiButton>
              </MuiGrid>
              <ConfirmationDialog
                open={showConfirm}
                setOpen={setShowConfirm}
                onConfirm={() => {
                  formikProps.resetForm();
                  handleBack();
                }}
              />
            </MuiGrid>
          </Form>
        );
      }}
    </Formik>
  );
};
