export const TerminalDegreeEnums = {
  MD: "steps.aboutYou.terminalDegrees.md",
  PHD: "steps.aboutYou.terminalDegrees.phd",
  IN_TRAINING: "steps.aboutYou.terminalDegrees.inTraining",
  NONE: "steps.aboutYou.terminalDegrees.none",
};

export const ResultingDegreeEnums = {
  DC: "steps.trainingAtStanford.resultingDegrees.dc",
  DDS: "steps.trainingAtStanford.resultingDegrees.dds",
  DMD: "steps.trainingAtStanford.resultingDegrees.dmd",
  DN_SC: "steps.trainingAtStanford.resultingDegrees.dn_sc",
  DO: "steps.trainingAtStanford.resultingDegrees.do",
  DPM: "steps.trainingAtStanford.resultingDegrees.dpm",
  DPT: "steps.trainingAtStanford.resultingDegrees.dpt",
  DSW: "steps.trainingAtStanford.resultingDegrees.dsw",
  DVM: "steps.trainingAtStanford.resultingDegrees.dvm",
  DR_PH: "steps.trainingAtStanford.resultingDegrees.dr_ph",
  ENG_D: "steps.trainingAtStanford.resultingDegrees.eng_d",
  MD: "steps.trainingAtStanford.resultingDegrees.md",
  ND: "steps.trainingAtStanford.resultingDegrees.nd",
  OD: "steps.trainingAtStanford.resultingDegrees.od",
  PHD: "steps.trainingAtStanford.resultingDegrees.phd",
  PHARM_D: "steps.trainingAtStanford.resultingDegrees.pharm_d",
  PSY_D: "steps.trainingAtStanford.resultingDegrees.psy_d",
  SCD: "steps.trainingAtStanford.resultingDegrees.scd",
  IN_TRAINING: "steps.trainingAtStanford.resultingDegrees.inTraining",
  NONE: "steps.trainingAtStanford.resultingDegrees.none",
};

export const PreDocTrainingGrantTypes = {
  F: "steps.trainingSupport.trainingGrantTypes.preDoc.f",
  NIH_HHS: "steps.trainingSupport.trainingGrantTypes.preDoc.nih_hhs",
  RA: "steps.trainingSupport.trainingGrantTypes.preDoc.ra",
  S: "steps.trainingSupport.trainingGrantTypes.preDoc.s",
  TA: "steps.trainingSupport.trainingGrantTypes.preDoc.ta",
  TG: "steps.trainingSupport.trainingGrantTypes.preDoc.tg",
  OTHER: "steps.trainingSupport.trainingGrantTypes.preDoc.other",
};

export const PostDocTrainingGrantTypes = {
  F: "steps.trainingSupport.trainingGrantTypes.postDoc.f",
  NIH_HHS: "steps.trainingSupport.trainingGrantTypes.postDoc.nih_hhs",
  RG: "steps.trainingSupport.trainingGrantTypes.postDoc.rg",
  TG: "steps.trainingSupport.trainingGrantTypes.postDoc.tg",
  OTHER: "steps.trainingSupport.trainingGrantTypes.postDoc.other",
};

export const TrainingGrantSources = {
  NSF: "steps.trainingSupport.trainingSources.nsf",
  OTHER_FED: "steps.trainingSupport.trainingSources.other_fed",
  UNIV: "steps.trainingSupport.trainingSources.univ",
  FDN: "steps.trainingSupport.trainingSources.fdn",
  NON_US: "steps.trainingSupport.trainingSources.non_us",
  OTHER: "steps.trainingSupport.trainingSources.other",
  NONE: "steps.trainingSupport.trainingSources.none",
};

export const EmploymentPositionTypes = {
  INITIAL_POSITION: 0,
  CURRENT_POSITION: 1,
};

export const EmploymentActivityTypes = {
  PRIMARILY_RESEARCH: "steps.yourEmployment.activityTypes.primarily-research",
  PRIMARILY_TEACHING: "steps.yourEmployment.activityTypes.primarily-teaching",
  PRIMARILY_CLINICAL: "steps.yourEmployment.activityTypes.primarily-clinical",
  RESEARCH_RELATED: "steps.yourEmployment.activityTypes.research-related",
  FURTHER_TRAINING: "steps.yourEmployment.activityTypes.further-training",
  UNRELATED_TO_RESEARCH:
    "steps.yourEmployment.activityTypes.unrelated-to-research",
};

export const EmploymentWorkforceSectorTypes = {
  ACADEMIA: "steps.yourEmployment.workforceSectorTypes.academia",
  GOVERMENT: "steps.yourEmployment.workforceSectorTypes.government",
  FOR_PROFIT: "steps.yourEmployment.workforceSectorTypes.for-profit",
  NONPROFIT: "steps.yourEmployment.workforceSectorTypes.nonprofit",
  OTHER: "steps.yourEmployment.workforceSectorTypes.other",
};

export const GrantRoles = {
  PRINCIPAL_INVESTIGATOR:
    "steps.subsequentGrants.grantRoles.principal-investigator",
  CO_INVESTIGATOR: "steps.subsequentGrants.grantRoles.co_investigator",
  FACULTY_COLLABORATOR:
    "steps.subsequentGrants.grantRoles.faculty_collaborator",
  STAFF_SCIENTIST: "steps.subsequentGrants.grantRoles.staff_scientist",
};

export const InvitationNotFoundUrl = "common.invitationNotFoundUrl";

export const stepName = {
  ABOUT_YOU: "ABOUT_YOU",
  ABOUT_TRAINING_AT_STANFORD: "ABOUT_TRAINING_AT_STANFORD",
  ABOUT_TRAINING_SUPPORT: "ABOUT_TRAINING_SUPPORT",
  ABOUT_YOUR_EMPLOYMENT: "ABOUT_YOUR_EMPLOYMENT",
  ABOUT_SUBSEQUENT_GRANTS: "ABOUT_SUBSEQUENT_GRANTS",
};

export const MD = "MD";
export const PHD = "PHD";
export const NONE = "NONE";
export const OTHER_D = "OTHER_D";
export const NIH_HHS = "NIH_HHS";
export const IN_TRAINING = "IN_TRAINING";
