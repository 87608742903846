// NOTE: This file is maintained in the parent project AS Common.
//       Your application should avoid modifying this file.

import { createRoot } from "react-dom/client";
import { AppRoot } from "AppRoot";
import { setupAxios } from "common";
import { onCLS, onINP, onLCP } from "web-vitals";
import "common/i18n";

setupAxios();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<AppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: onCLS(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
onCLS(() => {}); // Cumulative Layout Shift: https://web.dev/articles/cls
onINP(() => {}); // Interaction to Next Paint: https://web.dev/articles/inp
onLCP(() => {}); // Largest Contentful Paint: https://web.dev/articles/lcp
