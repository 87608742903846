import {
  Alert,
  Body,
  Footer,
  DevelopmentInfo,
  useAlerts,
  Header,
} from "common";
import { useTranslation } from "react-i18next";
import { LicenseInfo as MuiLicenseInfo } from "@mui/x-license";
import { MainContainer } from "common/components/Main-Container/MainContainer";
import { TraineePortal } from "app/components/TraineePortal";
import { TraineeProvider } from "app/services/traineeService";
import ErrorBoundary from "app/shared/ui/ErrorBoundary";

export const App = () => {
  const { alert } = useAlerts();
  const { t } = useTranslation();

  MuiLicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

  return (
    <>
      <DevelopmentInfo />
      <Header
        logoTitle={t("app.logoTitle")}
        logoSubtitle={t("app.logoSubtitle")}
      />
      <Body>
        {alert.exists && <Alert />}
        <MainContainer idAttr="main">
          <ErrorBoundary>
            <TraineeProvider>
              <TraineePortal />
            </TraineeProvider>
          </ErrorBoundary>
        </MainContainer>
      </Body>
      <Footer
        copyrightYear={t("app.copyrightYear")}
        productVersion={window.PRODUCT_VERSION || process.env.REACT_APP_VERSION}
      />
    </>
  );
};
