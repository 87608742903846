import { useEffect, useState, useContext, createContext } from "react";
import axios from "axios";
import { format, isValid } from "date-fns";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useAlerts } from "common";
import { formatComponentActivity } from "app/shared/utils";

// initial traineeData values
const initialValues = {
  uuid: "",
  email: "",
  firstName: "",
  lastName: "",
  middleName: "",
  researchTopic: "",
  traineeType: "",
  trainingEndDate: null,
  trainingStartDate: null,
  trainingStartDateYear: "",
  universityId: "",
  employments: [
    {
      position: "",
      positionType: "INITIAL_POSITION",
    },
    {
      position: "",
      positionType: "CURRENT_POSITION",
    },
  ],
  faculty: [
    {
      fullName: " ",
      universityId: "",
    },
  ],
  resultingDegrees: [
    {
      degree: "",
      year: "",
    },
  ],
  subsequentGrants: [
    {
      role: "",
      type: "",
      year: "",
    },
  ],
  terminalDegrees: [
    {
      degree: "",
      year: "",
    },
  ],
  trainingGrants: [
    {
      source: "",
      type: "",
      year: "TY1",
    },
  ],
};

/**
 * Sorts, and formats properties in the trainingData object.
 * @private
 * @param {object} traineeData
 * @returns {object} the updated training data object for UI.
 */
const prepareTraineeDataForUI = (traineeData) => {
  const preparedTraineeData = {
    ...traineeData,
    uuid: traineeData.uuid || "",
    email: traineeData.email || "",
    firstName: traineeData.firstName || "",
    lastName: traineeData.lastName || "",
    middleName: traineeData.middleName || "",
    researchTopic: traineeData.researchTopic || "",
    traineeType: traineeData.traineeType || "",
    trainingStartDateYear: traineeData.trainingStartDateYear || "",
    universityId: traineeData.universityId || "",
    trainingEndDate: traineeData.trainingEndDate
      ? new Date(traineeData.trainingEndDate)
      : null,
    trainingStartDate: traineeData.trainingStartDate
      ? new Date(traineeData.trainingStartDate)
      : null,
    faculty: traineeData.faculty || initialValues.faculty,
    resultingDegrees:
      traineeData.resultingDegrees || initialValues.resultingDegrees,
    subsequentGrants:
      traineeData.subsequentGrants || initialValues.subsequentGrants,
    terminalDegrees:
      traineeData.terminalDegrees || initialValues.terminalDegrees,
    trainingGrants: traineeData.trainingGrants || initialValues.trainingGrants,
    employments: traineeData.employments || initialValues.employments,
  };

  if (Array.isArray(preparedTraineeData.trainingGrants)) {
    preparedTraineeData.trainingGrants.sort(compareTrainingGrantYears);
    // Format bad component & activity data from API
    preparedTraineeData.trainingGrants = preparedTraineeData.trainingGrants.map(
      (obj) => {
        if (obj.support) {
          return {
            ...{ support: formatComponentActivity(obj.support) },
            ...obj,
          };
        } else {
          return obj;
        }
      }
    );
  }
  if (Array.isArray(preparedTraineeData.employments)) {
    const initialPosition = extractFirstPosition(
      preparedTraineeData.employments,
      "INITIAL_POSITION"
    ) || { position: "" };
    const currentPosition = extractFirstPosition(
      preparedTraineeData.employments,
      "CURRENT_POSITION"
    ) || { position: "" };
    traineeData.employments = [initialPosition, currentPosition];
  }

  return preparedTraineeData;
};

/**
 * Sorts grant years ascending
 */
const compareTrainingGrantYears = (a, b) => {
  return (
    parseInt(a.year.replace(/[a-zA-Z]+/gi, ""), 10) -
    parseInt(b.year.replace(/[a-zA-Z]+/gi, ""), 10)
  );
};

/**
 * Extracts the first object with a positionType property that matches the positionType argument.
 * @private
 * @param {array} employmentsArr the collection of objects to search.
 * @param {string} positionType the value to look for.
 * @returns {object} the employment object to return.
 */
const extractFirstPosition = (employmentsArr, positionType) => {
  return employmentsArr.find((entry) => entry.positionType === positionType);
};

export const TraineeContext = createContext(initialValues);

// Context provider
export const TraineeProvider = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const userToken = location.pathname.substring(1);
  const { setAlert, clearAlert } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [traineeData, setTraineeData] = useState(initialValues);

  useEffect(() => {
    const getTraineeData = async () => {
      try {
        clearAlert();
        setLoading(true);
        if (userToken) {
          const response = await axios({
            url: `/trainees/${userToken}`,
          });
          const preparedTraineeData = prepareTraineeDataForUI(response.data);
          setTraineeData(preparedTraineeData);
        } else {
          setAlert(
            "error",
            <Trans
              i18nKey={t("common.error.tokenError")}
              components={{
                customLink: (
                  <a href="mailto:hmtodd@stanford.edu">Halima Todd</a>
                ),
              }}
            />
          );
          setTraineeData(initialValues);
        }
      } catch (error) {
        if (error.status === 404 && error.code === "RESOURCE_NOT_FOUND") {
          setAlert(
            "error",
            <Trans
              i18nKey={t("common.error.tokenError")}
              components={{
                customLink: (
                  <a href="mailto:hmtodd@stanford.edu">Halima Todd</a>
                ),
              }}
            />
          );
        } else {
          setAlert("error", error.message);
        }
      } finally {
        setLoading(false);
      }
    };
    getTraineeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveTrainee = async (traineeData, handleNext) => {
    const saveTraineeRequest = {
      ...traineeData,
      trainingStartDate: isValid(traineeData.trainingStartDate)
        ? format(traineeData.trainingStartDate, "yyyy-MM-dd")
        : "",
      trainingEndDate: isValid(traineeData.trainingEndDate)
        ? format(traineeData.trainingEndDate, "yyyy-MM-dd")
        : "",
    };
    if (Array.isArray(saveTraineeRequest.terminalDegrees)) {
      saveTraineeRequest.terminalDegrees =
        saveTraineeRequest.terminalDegrees.filter(
          (entry) => entry.degree?.trim() !== ""
        );
    }
    if (Array.isArray(saveTraineeRequest.faculty)) {
      saveTraineeRequest.faculty = saveTraineeRequest.faculty.filter(
        (entry) => entry.fullName?.trim() !== ""
      );
    }
    if (Array.isArray(saveTraineeRequest.resultingDegrees)) {
      saveTraineeRequest.resultingDegrees =
        saveTraineeRequest.resultingDegrees.filter(
          (entry) => entry.degree?.trim() !== ""
        );
    }
    if (Array.isArray(saveTraineeRequest.trainingGrants)) {
      saveTraineeRequest.trainingGrants =
        saveTraineeRequest.trainingGrants.filter(
          (entry) => entry.source?.trim() !== ""
        );
    }
    if (Array.isArray(saveTraineeRequest.employments)) {
      saveTraineeRequest.employments = saveTraineeRequest.employments.filter(
        (entry) => entry.position
      );
    }
    if (Array.isArray(saveTraineeRequest.subsequentGrants)) {
      saveTraineeRequest.subsequentGrants =
        saveTraineeRequest.subsequentGrants.filter((entry) => entry.type);
    }
    try {
      clearAlert();
      const response = await axios({
        method: "put",
        url: `trainees/${userToken}`,
        data: saveTraineeRequest,
      });
      handleNext();
      return response;
    } catch (error) {
      setAlert("error", error.message);
    }
  };

  return (
    <TraineeContext.Provider
      value={{ traineeData, userToken, loading, saveTrainee }}
    >
      {children}
    </TraineeContext.Provider>
  );
};

// Custom hook
export const useTrainee = () => {
  return useContext(TraineeContext);
};
