// NOTE: This file is maintained in the parent project AS Common.
//       Your application should avoid modifying this file.

import { AlertsProvider, Theme, Page } from "common";
import { CssBaseline as MuiCssBaseline } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { App } from "app/App";

export const AppRoot = () => {
  return (
    <>
      <MuiThemeProvider theme={Theme}>
        <MuiCssBaseline />
        <BrowserRouter>
          <AlertsProvider>
            <Page>
              <App />
            </Page>
          </AlertsProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </>
  );
};
