import * as Yup from "yup";
import { IN_TRAINING, NIH_HHS, NONE, OTHER_D } from "app/shared/constants";

// Validation schema for About You step
export const aboutYouStepValidation = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  terminalDegrees: Yup.array().of(
    Yup.object()
      .shape({
        degree: Yup.string().required(),
        year: Yup.number().when("degree", (degree, schema) => {
          if (degree[0] === IN_TRAINING || degree[0] === NONE)
            return schema.notRequired();
        }),
      })
      .required()
  ),
});
// Validation schema for Training at Stanford step
export const trainingAtStanfordStepValidation = Yup.object().shape({
  faculty: Yup.array().of(
    Yup.object()
      .shape({
        fullName: Yup.string().required(),
        universityId: Yup.string().required(),
      })
      .required()
  ),
  resultingDegrees: Yup.array().of(
    Yup.object()
      .shape({
        degree: Yup.string().required(),
        year: Yup.number().when("degree", (degree, schema) => {
          if (degree[0] === IN_TRAINING || degree[0] === NONE)
            return schema.notRequired();
        }),
        equivalentDoctoralDegree: Yup.string().when("degree", {
          is: OTHER_D,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        }),
      })
      .required()
  ),
  trainingStartDate: Yup.date().required(),
  trainingEndDate: Yup.date().required(),
  researchTopic: Yup.string().required(),
});
// Validation schema for Training Support step
export const trainingSupportStepValidation = Yup.object().shape({
  trainingGrants: Yup.array().of(
    Yup.object().shape({
      source: Yup.string().when("type", {
        is: NIH_HHS,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required(),
      }),
      type: Yup.string().required(),
      year: Yup.string().required(),
      support: Yup.string().when("type", {
        is: NIH_HHS,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
    })
  ),
});
// Validation schema for Your Employment step
export const yourEmploymentStepValidation = Yup.object().shape({
  employments: Yup.array().of(
    Yup.object().shape({
      position: Yup.string().required("Position is required"),
    })
  ),
});
// Validation schema for Subsequent Grants step
export const subsequentGrantsStepValidation = Yup.object().shape({
  subsequentGrants: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().trim(),
      role: Yup.string().when("type", {
        is: (type) => !!type,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      year: Yup.number().when("type", {
        is: (type) => !!type,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
    })
  ),
});
