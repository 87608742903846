import { useState, Fragment, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid2 as MuiGrid,
  Link as MuiLink,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@mui/material";
import {
  Add as MuiAddIcon,
  Remove as MuiRemoveIcon,
} from "@mui/icons-material";
import { styles } from "app/shared/ui/styles";
import { getYearsDescending } from "app/shared/utils";
import {
  MD,
  PHD,
  NONE,
  IN_TRAINING,
  TerminalDegreeEnums,
} from "app/shared/constants";

export const AboutYouStep = ({ formikProps }) => {
  const { t } = useTranslation();
  const { values, errors, handleChange, setFieldValue } = formikProps;
  // Max terminal degrees
  const maxTerminalDegrees = 2;
  // State to manage filtered degree options
  const [filteredDegreeOptions, setFilteredDegreeOptions] = useState([]);

  // Getting all Years for terminal degree
  const degreeYears = getYearsDescending(99) || [];
  // Fn to check if degree is full
  const isFullDegree = (degree) => [MD, PHD].includes(degree);
  // Fn to check if degree can be added
  const canAddDegree = (idx) => {
    const degreesArr = values.terminalDegrees.map((degree) => degree.degree);
    return (
      ![IN_TRAINING, NONE].some((value) => {
        return degreesArr.includes(value);
      }) &&
      values.terminalDegrees.length < maxTerminalDegrees &&
      idx === values.terminalDegrees.length - 1
    );
  };
  // Fn to add terminal degree
  const addTerminalDegree = () => {
    const addTerminalDegree = values.terminalDegrees.concat({
      degree: "",
      year: "",
    });
    setFieldValue("terminalDegrees", addTerminalDegree);
  };
  // Fn to remove terminal degree
  const removeTerminalDegree = (idx) => {
    const filteredTerminalDegrees = values.terminalDegrees.filter(
      (degree, index) => index !== idx
    );
    setFieldValue("terminalDegrees", filteredTerminalDegrees);
  };
  // All terminal degree options
  const allDegreeOptions = Object.keys(TerminalDegreeEnums);
  // Fn to filter degree options
  const filterDegreeOption = useCallback(
    (index, value) => {
      if (index === 0 && value === MD) {
        const options = [...allDegreeOptions];
        // Remove MD, IN_TRAINING, and NONE
        const filteredOptions = options.filter(
          (option) => option !== MD && option !== IN_TRAINING && option !== NONE
        );
        return setFilteredDegreeOptions(filteredOptions);
      } else if (index === 0 && value === PHD) {
        const options = [...allDegreeOptions];
        // Remove PHD, IN_TRAINING, and NONE
        const filteredOptions = options.filter(
          (option) =>
            option !== PHD && option !== IN_TRAINING && option !== NONE
        );
        return setFilteredDegreeOptions(filteredOptions);
      } else {
        // Default case: return all options
        return filteredDegreeOptions;
      }
    },
    [allDegreeOptions, filteredDegreeOptions]
  );
  // Fn to get terminal degree options
  const getTerminalDegreeOption = (index) => {
    if (index === 1) {
      return filteredDegreeOptions;
    } else {
      return allDegreeOptions;
    }
  };

  // useEffect to filter degree options on initial load
  useEffect(() => {
    filterDegreeOption(0, values.terminalDegrees?.[0].degree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.terminalDegrees]);

  return (
    <MuiGrid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      sx={styles.container}
      spacing={2}
    >
      <MuiGrid sx={styles.stepItem}>
        <MuiTypography variant="h2" sx={styles.stepTitle}>
          {t("steps.aboutYou.h2Title")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiTextField
          name="firstName"
          label={t("steps.aboutYou.firstName")}
          value={values.firstName || ""}
          onChange={handleChange}
          required
          error={!!errors.firstName}
          variant="outlined"
          inputProps={{ maxLength: 100 }}
          fullWidth
        />
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiTextField
          name="middleName"
          label={t("steps.aboutYou.middleName")}
          value={values.middleName || ""}
          onChange={handleChange}
          variant="outlined"
          inputProps={{ maxLength: 100 }}
        />
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiTextField
          name="lastName"
          label={t("steps.aboutYou.lastName")}
          value={values.lastName || ""}
          onChange={handleChange}
          error={!!errors.lastName}
          required
          variant="outlined"
          inputProps={{ maxLength: 100 }}
          fullWidth
        />
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiGrid
          container
          direction="column"
          alignItems="flex-start"
          spacing={1}
        >
          <MuiGrid>
            <MuiTypography variant="h4" sx={styles.stepSubtitle}>
              {t("steps.aboutYou.degrees")}
            </MuiTypography>
          </MuiGrid>
          {values.terminalDegrees.map((field, idx) => {
            return (
              <Fragment key={`${field.degree}-${idx}`}>
                <MuiGrid sx={styles.stepItem}>
                  <MuiTextField
                    select
                    name={`terminalDegrees[${idx}].degree`}
                    label={t("common.degree")}
                    value={field.degree || ""}
                    onChange={(event) => {
                      filterDegreeOption(idx, event.target.value);
                      setFieldValue(
                        `terminalDegrees[${idx}].degree`,
                        event.target.value
                      );
                    }}
                    required
                    error={!!errors?.terminalDegrees?.[idx]?.degree}
                    variant="outlined"
                    fullWidth
                  >
                    {getTerminalDegreeOption(idx).map((option) => (
                      <MuiMenuItem key={option} value={option}>
                        {t(TerminalDegreeEnums[option])}
                      </MuiMenuItem>
                    ))}
                  </MuiTextField>
                </MuiGrid>
                {isFullDegree(field.degree) && (
                  <MuiGrid sx={styles.stepItem}>
                    <MuiTextField
                      select
                      name={`terminalDegrees[${idx}].year`}
                      label={t("common.year")}
                      value={field.year || ""}
                      onChange={handleChange}
                      required
                      error={!!errors?.terminalDegrees?.[idx]?.year}
                      variant="outlined"
                      fullWidth
                    >
                      {degreeYears.map((year) => (
                        <MuiMenuItem key={year} value={year}>
                          {year}
                        </MuiMenuItem>
                      ))}
                    </MuiTextField>
                  </MuiGrid>
                )}
                <MuiGrid sx={styles.stepItem}>
                  <MuiGrid container direction="row" justifyContent="end">
                    {canAddDegree(idx) && (
                      <MuiLink
                        sx={styles.degreeLink}
                        component="button"
                        type="button"
                        onClick={addTerminalDegree}
                      >
                        <MuiAddIcon /> {t("common.add")} {t("common.degree")}
                      </MuiLink>
                    )}
                    {values.terminalDegrees.length > 1 &&
                      idx === values.terminalDegrees.length - 1 && (
                        <MuiLink
                          sx={styles.degreeLink}
                          component="button"
                          type="button"
                          onClick={() => {
                            removeTerminalDegree(idx);
                          }}
                        >
                          <MuiRemoveIcon /> {t("common.remove")}{" "}
                          {t("common.degree")}
                        </MuiLink>
                      )}
                  </MuiGrid>
                </MuiGrid>
              </Fragment>
            );
          })}
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
