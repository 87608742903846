import { Theme } from "common";

// This file contains the commonly used styles for the UI components.
export const styles = {
  buttonsWrapper: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  headerTitleRoot: {
    fontSize: "34px",
    fontWeight: 500,
    textAlign: "center",
  },
  headerSubTitle1: {
    fontSize: "18px",
    fontWeight: 600,
    textAlign: "center",
    color: "#333333",
  },
  container: {
    width: "100%",
    margin: 0,
    "& .MuiFormControl-root": {
      width: "100%",
      margin: 0,
    },
    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  stepTitle: {
    fontSize: "24px",
    fontWeight: 400,
    textAlign: "left",
  },
  stepSubtitle: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    color: "#333333",
  },
  stepItem: {
    "& .MuiSvgIcon-root": {
      color: Theme.palette.text.primary,
    },
    padding: 0,
    width: "100%",
  },
  degreeLink: {
    paddingLeft: "16px",
    textDecoration: "none",
    verticalAlign: "middle",
    display: "inline-flex",
    color: "#009abb",
    fontSize: "16px",
    fontWeight: 600,
    textTransform: "uppercase",
    backgroundColor: "transparent",
    border: "none",
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
      color: "#009abb",
    },
    "& .MuiSvgIcon-root": {
      color: Theme.palette.primary.main,
      height: "0.6em",
      width: "0.6em",
      marginTop: "0.1em",
      marginRight: "0.05em",
    },
  },
};
