import { useTranslation } from "react-i18next";
import { Grid2 as MuiGrid, Typography as MuiTypography } from "@mui/material";
import { CheckCircle as MuiCheckCircleIcon } from "@mui/icons-material";
import { styles } from "app/shared/ui/styles";

export const SubmittedStep = () => {
  const { t } = useTranslation();

  return (
    <MuiGrid container direction="column" spacing={4}>
      <MuiGrid>
        <MuiTypography variant="h2" sx={styles.headerTitleRoot}>
          {t("steps.submitted.h2Title")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid>
        <MuiTypography variant="h2" sx={styles.headerTitleRoot}>
          <MuiCheckCircleIcon fontSize="large" sx={{ color: "#009779" }} />
        </MuiTypography>
      </MuiGrid>
      <MuiGrid>
        <MuiTypography variant="h3" sx={styles.headerSubTitle1}>
          {t("steps.submitted.successfullySubmitted")}
        </MuiTypography>
      </MuiGrid>
    </MuiGrid>
  );
};
