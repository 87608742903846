import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid2 as MuiGrid,
  Link as MuiLink,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@mui/material";
import {
  Add as MuiAddIcon,
  Remove as MuiRemoveIcon,
} from "@mui/icons-material";
import {
  PreDocTrainingGrantTypes,
  PostDocTrainingGrantTypes,
  TrainingGrantSources,
} from "app/shared/constants";
import { styles } from "app/shared/ui/styles";

export const TrainingSupportStep = ({ formikProps, callbacks }) => {
  const maxTrainingGrants = 20;
  const { t } = useTranslation();
  const { values, errors, handleChange, setFieldValue } = formikProps;

  // Check if grant type is NIH
  const isGrantTypeNih = (type) => type.includes("NIH_HHS");

  // Get grant years
  const getGrantYears = (numYears) => {
    const grantYears = {};
    [...Array(numYears).keys()].forEach(
      (x) => (grantYears[`TY${x + 1}`] = `Training Year ${x + 1}`)
    );
    return grantYears;
  };

  const trainingYears = getGrantYears(20);
  const trainingTypes =
    values.traineeType === "PRE_DOC"
      ? PreDocTrainingGrantTypes
      : PostDocTrainingGrantTypes;

  const addTrainingGrant = () => {
    const newValue = {
      year: `TY${values.trainingGrants.length + 1}`,
      type: "",
    };
    const newGrant = values.trainingGrants.concat(newValue);
    setFieldValue("trainingGrants", newGrant);
  };

  const removeTrainingGrant = (idx) => {
    const filteredGrants = values.trainingGrants.filter(
      (grant, index) => index !== idx
    );
    setFieldValue("trainingGrants", filteredGrants);
  };

  return (
    <MuiGrid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      sx={styles.container}
      spacing={2}
    >
      <MuiGrid sx={styles.stepItem}>
        <MuiTypography variant="h2" sx={styles.stepTitle}>
          {t("steps.trainingSupport.h2Title")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiGrid
          container
          direction="column"
          alignItems="flex-start"
          spacing={2}
        >
          {values.trainingGrants.map((field, idx) => {
            return (
              <Fragment key={`${field}-${idx}`}>
                <MuiGrid sx={styles.stepItem}>
                  <MuiTypography variant="h4" sx={styles.stepSubtitle}>
                    {trainingYears[field.year]}
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid sx={styles.stepItem}>
                  <MuiTextField
                    select
                    id={`trainingGrants[${idx}].type`}
                    name={`trainingGrants[${idx}].type`}
                    label={t("steps.trainingSupport.trainingType")}
                    value={field.type || ""}
                    onChange={handleChange}
                    required
                    error={!!errors?.trainingGrants?.[idx]?.type}
                    variant="outlined"
                  >
                    {Object.keys(trainingTypes).map((option) => (
                      <MuiMenuItem key={option} value={option}>
                        {t(trainingTypes[option])}
                      </MuiMenuItem>
                    ))}
                  </MuiTextField>
                </MuiGrid>
                {field?.type && (
                  <MuiGrid sx={styles.stepItem}>
                    {!isGrantTypeNih(field.type) ? (
                      <MuiTextField
                        select
                        id={`trainingGrants[${idx}].source`}
                        name={`trainingGrants[${idx}].source`}
                        label={t("steps.trainingSupport.source")}
                        value={field.source || ""}
                        onChange={handleChange}
                        required
                        error={!!errors?.trainingGrants?.[idx]?.source}
                        variant="outlined"
                      >
                        {Object.keys(TrainingGrantSources).map((option) => (
                          <MuiMenuItem key={option} value={option}>
                            {t(TrainingGrantSources[option])}
                          </MuiMenuItem>
                        ))}
                      </MuiTextField>
                    ) : (
                      <MuiTextField
                        required
                        id={`trainingGrants[${idx}].support`}
                        name={`trainingGrants[${idx}].support`}
                        value={field.support || ""}
                        onChange={(event) => {
                          setFieldValue(
                            `trainingGrants[${idx}].support`,
                            event.target.value.toUpperCase()
                          );
                        }}
                        error={!!errors?.trainingGrants?.[idx]?.support}
                        label={t("steps.trainingSupport.support")}
                        variant="outlined"
                        helperText={t("steps.trainingSupport.supportHelpText")}
                        inputProps={{ maxLength: 30 }}
                      />
                    )}
                  </MuiGrid>
                )}
                <MuiGrid sx={styles.stepItem}>
                  <MuiGrid container direction="row" justifyContent="end">
                    {values.trainingGrants.length < maxTrainingGrants &&
                      idx === values.trainingGrants.length - 1 && (
                        <MuiLink
                          sx={styles.degreeLink}
                          component="button"
                          type="button"
                          onClick={addTrainingGrant}
                        >
                          <MuiAddIcon /> {t("common.add")}{" "}
                          {t("steps.trainingSupport.trainingYear")}
                        </MuiLink>
                      )}
                    {values.trainingGrants.length > 1 &&
                      idx === values.trainingGrants.length - 1 && (
                        <MuiLink
                          sx={styles.degreeLink}
                          component="button"
                          type="button"
                          onClick={() => removeTrainingGrant(idx)}
                        >
                          <MuiRemoveIcon /> {t("common.remove")}{" "}
                          {t("steps.trainingSupport.trainingYear")}
                        </MuiLink>
                      )}
                  </MuiGrid>
                </MuiGrid>
              </Fragment>
            );
          })}
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
