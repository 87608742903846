import { Fragment } from "react";
import {
  Link as MuiLink,
  Grid2 as MuiGrid,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@mui/material";
import {
  Add as MuiAddIcon,
  Remove as MuiRemoveIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { GrantRoles } from "app/shared/constants";
import { getYearsDescending } from "app/shared/utils";
import { styles } from "app/shared/ui/styles";

export const SubsequentGrantsStep = ({ formikProps, callbacks }) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue, handleChange } = formikProps;

  const maxSubsequentGrants = 5;
  const grantYears = getYearsDescending(20);

  const addSubsequentGrant = () => {
    const newSubsequentGrant = values.subsequentGrants.concat({
      type: "",
      role: "",
      year: "",
    });
    setFieldValue("subsequentGrants", newSubsequentGrant);
  };

  const removeSubsequentGrant = (idx) => {
    const filteredSubsequentGrant = values.subsequentGrants.filter(
      (grant, index) => index !== idx
    );
    setFieldValue("subsequentGrants", filteredSubsequentGrant);
  };

  return (
    <MuiGrid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      sx={styles.container}
      spacing={2}
    >
      <MuiGrid sx={styles.stepItem}>
        <MuiTypography variant="h2" sx={styles.stepTitle}>
          {t("steps.subsequentGrants.h2Title")}
        </MuiTypography>
      </MuiGrid>

      <MuiGrid sx={styles.stepItem}>
        <MuiGrid
          container
          direction="column"
          alignItems="flex-start"
          spacing={2}
        >
          <MuiGrid sx={styles.stepItem}>
            <MuiTypography variant="h4" sx={styles.stepSubtitle}>
              {t("steps.subsequentGrants.grants")}
            </MuiTypography>
          </MuiGrid>
          {values.subsequentGrants.map((field, idx) => {
            const roleAndYearIsRequired = !!field.type.trim();
            return (
              <Fragment key={`${field}-${idx}`}>
                <MuiGrid sx={styles.stepItem}>
                  <MuiTextField
                    name={`subsequentGrants[${idx}].type`}
                    label={t("steps.trainingSupport.support")}
                    value={field.type || ""}
                    onChange={handleChange}
                    error={!!errors?.subsequentGrants?.[idx]?.type}
                    variant="outlined"
                    helperText={t("steps.trainingSupport.supportHelpText")}
                    inputProps={{ maxLength: 30 }}
                  />
                </MuiGrid>
                <MuiGrid sx={styles.stepItem}>
                  <MuiTextField
                    select
                    name={`subsequentGrants[${idx}].role`}
                    label={t("steps.subsequentGrants.grantRole")}
                    value={field.role || ""}
                    onChange={handleChange}
                    variant="outlined"
                    required={roleAndYearIsRequired}
                    error={!!errors?.subsequentGrants?.[idx]?.role}
                  >
                    {Object.keys(GrantRoles).map((option) => (
                      <MuiMenuItem key={option} value={option}>
                        {t(GrantRoles[option])}
                      </MuiMenuItem>
                    ))}
                  </MuiTextField>
                </MuiGrid>
                <MuiGrid sx={styles.stepItem}>
                  <MuiTextField
                    select
                    name={`subsequentGrants[${idx}].year`}
                    label={t("steps.subsequentGrants.grantYear")}
                    value={field.year || ""}
                    onChange={handleChange}
                    required={roleAndYearIsRequired}
                    error={!!errors?.subsequentGrants?.[idx]?.year}
                    variant="outlined"
                  >
                    <MuiMenuItem value=""></MuiMenuItem>
                    {grantYears.map((option) => (
                      <MuiMenuItem key={option} value={option}>
                        {option}
                      </MuiMenuItem>
                    ))}
                  </MuiTextField>
                </MuiGrid>
                <MuiGrid sx={styles.stepItem}>
                  <MuiGrid container direction="row" justifyContent="end">
                    {values.subsequentGrants.length < maxSubsequentGrants &&
                      idx === values.subsequentGrants.length - 1 && (
                        <MuiLink
                          sx={styles.degreeLink}
                          component="button"
                          type="button"
                          onClick={addSubsequentGrant}
                        >
                          <MuiAddIcon /> {t("common.add")}{" "}
                          {t("steps.subsequentGrants.grant")}
                        </MuiLink>
                      )}
                    {values.subsequentGrants.length > 1 &&
                      idx === values.subsequentGrants.length - 1 && (
                        <MuiLink
                          sx={styles.degreeLink}
                          component="button"
                          type="button"
                          onClick={() => removeSubsequentGrant(idx)}
                        >
                          <MuiRemoveIcon /> {t("common.remove")}{" "}
                          {t("steps.subsequentGrants.grant")}
                        </MuiLink>
                      )}
                  </MuiGrid>
                </MuiGrid>
              </Fragment>
            );
          })}
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
