import { Container as MuiContainer, Box as MuiBox } from "@mui/material";
import PropTypes from "prop-types";

const containerStyles = {
  overflowX: "auto",
};

/* TGDS Portals Specific Changes
/* 1. Removed React Router Import 
/* 2. Added {children} arg to MainContainer
*/
export const MainContainer = ({ idAttr, children }) => {
  return (
    <MuiContainer maxWidth="false" sx={containerStyles}>
      <MuiBox
        id={idAttr}
        display="inline-block"
        my="30px"
        sx={{
          display: "flex",
          justifyContent: "center",
          "@media (min-width: 1480px)": {
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        {children}
      </MuiBox>
    </MuiContainer>
  );
};

MainContainer.propTypes = {
  idAttr: PropTypes.string.isRequired,
};
