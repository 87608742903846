import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid2 as MuiGrid,
  Link as MuiLink,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  Add as MuiAddIcon,
  Remove as MuiRemoveIcon,
} from "@mui/icons-material";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  NONE,
  OTHER_D,
  IN_TRAINING,
  ResultingDegreeEnums,
} from "app/shared/constants";
import { getYearsDescending, isString } from "app/shared/utils";
import { styles } from "app/shared/ui/styles";
import { FacultyAutocomplete } from "./FacultyAutocomplete";

// Gets Degree Years
const degreeYears = getYearsDescending(21);

export const TrainingAtStanfordStep = ({ formikProps }) => {
  const maxFaculty = 3;
  const maxResultingDegrees = 5;
  const { t } = useTranslation();
  const { values, errors, handleChange, setFieldValue } = formikProps;

  // Check if degree is full
  const isFullDegree = (degree) =>
    isString(degree) &&
    degree.length > 0 &&
    ![IN_TRAINING, NONE].includes(degree);

  const addFaculty = () => {
    const addedFaculty = values.faculty.concat({
      fullname: "",
      universityId: "",
    });
    setFieldValue("faculty", addedFaculty);
  };

  const removeFaculty = (idx) => {
    const filteredFaculty = values.faculty.filter(
      (faculty, index) => index !== idx
    );
    setFieldValue("faculty", filteredFaculty);
  };

  /**
   * Returns degree options.
   * @private
   * @param {boolean} filterNonDegreeOptions flag to return only full degrees.
   * @returns {Array<string>} an array of degrees.
   */
  const getDegreeOptions = (filterNonDegreeOptions = false) =>
    Object.keys(ResultingDegreeEnums).filter((degree) => {
      return filterNonDegreeOptions ? isFullDegree(degree) : true;
    });

  /**
   * Returns a function that provides a label for a degree.
   * @private
   * @param {string} altLabel the alternate label to display if one is not avail.
   * @returns {string} the label.
   */
  const getDegreeLabel = (altLabel) => (option) => {
    if (ResultingDegreeEnums.hasOwnProperty(option)) {
      return t(ResultingDegreeEnums[option]);
    }
    return option === OTHER_D ? altLabel || "" : option || "";
  };

  /**
   * Updates a property of a resulting degree at a specified index.
   * @param {number} idx - The index of the object to update.
   * @param {string} keyName - The name of the property to update.
   * @param {any} value - The new value for the specified property.
   */
  const updateResultingDegree = (idx, keyName, value) => {
    // Map through the degrees, updating only the target index
    const newResultingDegree = values.resultingDegrees.map(
      (degreeEntry, fidx) =>
        fidx === idx
          ? keyName === "degree" && !getDegreeOptions().includes(value)
            ? {
                ...degreeEntry,
                degree: OTHER_D,
                equivalentDoctoralDegree: value,
              }
            : { ...degreeEntry, [keyName]: value }
          : degreeEntry
    );

    setFieldValue("resultingDegrees", newResultingDegree);
    return value;
  };

  const addResultingDegree = () => {
    const addedDegrees = values.resultingDegrees.concat({ degree: "" });
    setFieldValue("resultingDegrees", addedDegrees);
  };

  const removeResultingDegree = (idx) => {
    const filteredDegrees = values.resultingDegrees.filter(
      (degree, index) => index !== idx
    );
    setFieldValue("resultingDegrees", filteredDegrees);
  };

  return (
    <MuiGrid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      sx={styles.container}
      spacing={2}
    >
      <MuiGrid sx={styles.stepItem}>
        <MuiTypography variant="h2" sx={styles.stepTitle}>
          {t("steps.trainingAtStanford.h2Title")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiTypography variant="h4" sx={styles.stepSubtitle}>
          {t("steps.trainingAtStanford.faculty")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiGrid
          container
          direction="column"
          alignItems="flex-start"
          spacing={2}
        >
          {values.faculty.map((field, idx) => {
            return (
              <Fragment key={`${field?.universityId}-${idx}`}>
                <MuiGrid sx={styles.stepItem}>
                  <FacultyAutocomplete
                    id={`${field?.universityId}-${idx}`}
                    name={`faculty[${idx}]`}
                    value={field}
                    error={!!errors?.faculty?.[idx]}
                    label={t("steps.trainingAtStanford.facultyName")}
                    setFieldValue={setFieldValue}
                  />
                </MuiGrid>
                {idx === values.faculty.length - 1 && (
                  <MuiGrid sx={styles.stepItem}>
                    <MuiGrid container direction="row" justifyContent="end">
                      <>
                        {values.faculty.length < maxFaculty && (
                          <MuiLink
                            sx={styles.degreeLink}
                            component="button"
                            type="button"
                            onClick={addFaculty}
                          >
                            <MuiAddIcon /> {t("common.add")}{" "}
                            {t("steps.trainingAtStanford.faculty")}
                          </MuiLink>
                        )}
                        {values.faculty.length > 1 && (
                          <MuiLink
                            sx={styles.degreeLink}
                            component="button"
                            type="button"
                            onClick={() => {
                              removeFaculty(idx);
                            }}
                          >
                            <MuiRemoveIcon /> {t("common.remove")}{" "}
                            {t("steps.trainingAtStanford.faculty")}
                          </MuiLink>
                        )}
                      </>
                    </MuiGrid>
                  </MuiGrid>
                )}
              </Fragment>
            );
          })}
        </MuiGrid>
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
          <MuiDatePicker
            name="trainingStartDate"
            views={["year", "month"]}
            value={values.trainingStartDate || null}
            maxDate={values.trainingEndDate || null}
            onChange={(newValue) =>
              setFieldValue("trainingStartDate", newValue)
            }
            label={t("steps.trainingAtStanford.trainingStartDate")}
            clearable
            slotProps={{
              textField: {
                required: true,
                variant: "outlined",
                error: !!errors.trainingStartDate,
              },
            }}
          />
        </MuiLocalizationProvider>
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
          <MuiDatePicker
            name="trainingEndDate"
            views={["year", "month"]}
            minDate={values.trainingStartDate || null}
            value={values.trainingEndDate || null}
            onChange={(newValue) => setFieldValue("trainingEndDate", newValue)}
            label={t("steps.trainingAtStanford.trainingEndDate")}
            clearable
            slotProps={{
              textField: {
                required: true,
                variant: "outlined",
                error: !!errors.trainingEndDate,
              },
            }}
          />
        </MuiLocalizationProvider>
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiTypography variant="h4" sx={styles.stepSubtitle}>
          {t("steps.trainingAtStanford.resultingDegree")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiGrid
          container
          direction="column"
          alignItems="flex-start"
          spacing={2}
        >
          {values.resultingDegrees.map((field, idx) => {
            const degree = field.degree || field.equivalentDoctoralDegree;
            const key =
              field.degree && field.degree !== OTHER_D
                ? field.degree
                : `equivalent-${idx}`;
            return (
              <Fragment key={`${key}-${idx}`}>
                <MuiGrid sx={styles.stepItem}>
                  <MuiAutocomplete
                    name={`resultingDegrees[${idx}].degree`}
                    value={field.degree || ""}
                    onChange={(event, newValue) =>
                      updateResultingDegree(idx, "degree", newValue)
                    }
                    options={getDegreeOptions(idx > 0)} // Provide all options on first input
                    getOptionLabel={getDegreeLabel(
                      field.equivalentDoctoralDegree
                    )}
                    selectOnFocus
                    autoSelect
                    handleHomeEndKeys
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        required
                        error={
                          !!errors?.resultingDegrees?.[idx]?.degree ||
                          !!errors?.resultingDegrees?.[idx]
                            ?.equivalentDoctoralDegree
                        }
                        label={t("common.degree")}
                        variant="outlined"
                      />
                    )}
                  />
                </MuiGrid>
                {isFullDegree(degree) && (
                  <MuiGrid sx={styles.stepItem}>
                    <MuiTextField
                      select
                      name={`resultingDegrees[${idx}].year`}
                      label={t("common.year")}
                      value={field.year || ""}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        updateResultingDegree(idx, "year", newValue);
                      }}
                      required
                      error={!!errors?.resultingDegrees?.[idx]?.year}
                      variant="outlined"
                    >
                      {degreeYears.map((option) => (
                        <MuiMenuItem key={option} value={option}>
                          {option}
                        </MuiMenuItem>
                      ))}
                    </MuiTextField>
                  </MuiGrid>
                )}
                <MuiGrid sx={styles.stepItem}>
                  <MuiGrid container direction="row" justifyContent="end">
                    <>
                      {values.resultingDegrees.length < maxResultingDegrees &&
                        idx === values.resultingDegrees.length - 1 && (
                          <MuiLink
                            sx={styles.degreeLink}
                            component="button"
                            type="button"
                            onClick={addResultingDegree}
                          >
                            <MuiAddIcon /> {t("common.add")}{" "}
                            {t("common.degree")}
                          </MuiLink>
                        )}
                      {values.resultingDegrees.length > 1 &&
                        idx === values.resultingDegrees.length - 1 && (
                          <MuiLink
                            sx={styles.degreeLink}
                            component="button"
                            type="button"
                            onClick={() => {
                              removeResultingDegree(idx);
                            }}
                          >
                            <MuiRemoveIcon /> {t("common.remove")}{" "}
                            {t("common.degree")}
                          </MuiLink>
                        )}
                    </>
                  </MuiGrid>
                </MuiGrid>
              </Fragment>
            );
          })}
        </MuiGrid>
      </MuiGrid>
      <MuiGrid sx={styles.stepItem}>
        <MuiTextField
          name="researchTopic"
          label={t("steps.trainingAtStanford.researchTopic")}
          value={values.researchTopic || ""}
          onChange={handleChange}
          required
          error={!!errors.researchTopic}
          variant="outlined"
          inputProps={{ maxLength: 150 }}
        />
      </MuiGrid>
    </MuiGrid>
  );
};
