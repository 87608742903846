// src/services/facultyService.js
// Faculty service for the "faculty/" endpoint, handling faculty state management and API errors.
// Exports a useFaculty custom hook for accessing faculty data, such as displayName and permissions.
import { useState } from "react";
import axios from "axios";
import { useAlerts } from "common";
import { useDebounce } from "app/shared/utils";

export const useFacultySearchApi = () => {
  const maxResults = 10;
  const { setAlert, clearAlert } = useAlerts();
  const [loading, setLoading] = useState(false);

  // Marshall faculty search results for UI
  const marshallFacultySearchResults = (searchResults) => {
    return searchResults.map((faculty) => {
      return {
        displayText: faculty.displayText,
        ...faculty.value,
      };
    });
  };

  // Get faculty search results
  const getFacultySearchResults = async (inputValue, setFacultyOptions) => {
    setLoading(true);
    clearAlert();
    try {
      const response = await axios({
        method: "get",
        url: `/faculty/autocomplete?value=${inputValue}&size=${maxResults}`,
      });
      if (response.data.count > 0) {
        const marshalledFacultySearchResults = marshallFacultySearchResults(
          response.data.values
        );
        return setFacultyOptions(marshalledFacultySearchResults);
      } else {
        return setFacultyOptions([]);
      }
    } catch (error) {
      setAlert("error", error.message, true, true);
    } finally {
      setLoading(false);
    }
  };

  // Debounce faculty search results
  const getFacultySearchResultsDebounced = useDebounce(
    getFacultySearchResults,
    200
  );

  return { loading, getFacultySearchResultsDebounced };
};
