import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid2 as MuiGrid,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@mui/material";
import {
  EmploymentActivityTypes,
  EmploymentWorkforceSectorTypes,
} from "app/shared/constants";
import { styles } from "app/shared/ui/styles";

export const YourEmploymentStep = ({ formikProps, callbacks }) => {
  const { t } = useTranslation();
  const { values, errors, handleChange } = formikProps;

  return (
    <MuiGrid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      sx={styles.container}
      spacing={2}
    >
      <MuiGrid sx={styles.stepItem}>
        <MuiTypography variant="h2" sx={styles.stepTitle}>
          {t("steps.yourEmployment.h2Title")}
        </MuiTypography>
      </MuiGrid>
      {values.employments.map((field, idx) => {
        const positionIsRequired =
          !field.department?.trim() ||
          !field.institution?.trim() ||
          !field.workforceSector?.trim() ||
          !field.activity?.trim();

        return (
          <Fragment key={`${field.positionType}-${idx}`}>
            <MuiGrid sx={styles.stepItem}>
              <MuiTypography variant="h4" sx={styles.stepSubtitle}>
                {idx === 0
                  ? t("steps.yourEmployment.initialPosition")
                  : t("steps.yourEmployment.currentPosition")}
              </MuiTypography>
            </MuiGrid>
            <MuiGrid sx={styles.stepItem}>
              <MuiTextField
                name={`employments[${idx}].position`}
                label={t("steps.yourEmployment.position")}
                value={field.position || ""}
                required={positionIsRequired}
                error={
                  positionIsRequired
                    ? !!errors?.employments?.[idx]?.position
                    : false
                }
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                onChange={handleChange}
              />
            </MuiGrid>
            <MuiGrid sx={styles.stepItem}>
              <MuiTextField
                name={`employments[${idx}].department`}
                label={t("steps.yourEmployment.department")}
                value={field.department || ""}
                error={!!errors?.employments?.[idx]?.department}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                onChange={handleChange}
              />
            </MuiGrid>
            <MuiGrid sx={styles.stepItem}>
              <MuiTextField
                name={`employments[${idx}].institution`}
                label={t("steps.yourEmployment.institution")}
                value={field.institution || ""}
                error={!!errors?.employments?.[idx]?.institution}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                onChange={handleChange}
              />
            </MuiGrid>
            <MuiGrid sx={styles.stepItem}>
              <MuiTextField
                select
                name={`employments[${idx}].workforceSector`}
                label={t("steps.yourEmployment.workforceSector")}
                value={field.workforceSector || ""}
                onChange={handleChange}
                error={!!errors?.employments?.[idx]?.workforceSector}
                variant="outlined"
              >
                {Object.keys(EmploymentWorkforceSectorTypes).map((option) => (
                  <MuiMenuItem key={option} value={option}>
                    {t(EmploymentWorkforceSectorTypes[option])}
                  </MuiMenuItem>
                ))}
              </MuiTextField>
            </MuiGrid>
            <MuiGrid sx={styles.stepItem}>
              <MuiTextField
                select
                name={`employments[${idx}].activity`}
                label={t("steps.yourEmployment.activity")}
                value={field.activity || ""}
                onChange={handleChange}
                error={!!errors?.employments?.[idx]?.activity}
                variant="outlined"
              >
                {Object.keys(EmploymentActivityTypes).map((option) => (
                  <MuiMenuItem key={option} value={option}>
                    {t(EmploymentActivityTypes[option])}
                  </MuiMenuItem>
                ))}
              </MuiTextField>
            </MuiGrid>
          </Fragment>
        );
      })}
    </MuiGrid>
  );
};
